import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "middleware"
    }}>{`Middleware`}</h1>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Endpoint`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Params`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Service`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`/autocomplete`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`q={search}`}</inlineCode>{`, `}<inlineCode parentName="td">{`sessionToken={token}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://developers.google.com/places/web-service/autocomplete"
            }}>{`Place autocomplete`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`/details`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`q={search}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://developers.google.com/places/web-service/details"
            }}>{`Place details`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import express from 'express'
import googleMapsMiddleware from '@emcasa/places-autocomplete/middleware'

const server = express()

server.use('/maps', googleMapsMiddleware({
  apiKey: '...',
  // ... @google/maps client options
}))

`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      